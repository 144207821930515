<template>
  <div class="examineeData">
    <!-- 考生信息组件 -->
    <div class="title">考生信息</div>
    <div class="slogan">欢迎您,{{ person.nickName }}</div>

    <div class="button-list">
      <div class="bottom-item" @click="go">进入系统</div>
      <div class="bottom-item" @click="go">个人信息</div>
      <div class="bottom-item" @click="go">修改密码</div>
      <div class="bottom-item unsubscribe" @click="clear()">注销</div>
    </div>
  </div>
</template>

<script>
import common from "../../../api/common";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      person: JSON.parse(Cookies.get("person")),
      uuid: "",
      uuids: "",
    };
  },
  created() {},
  methods: {
    go() {
      var param = {
        integer: 2,
      };
      common.urltest(param).then((res) => {
        if (res.code == 200) {
          // window.location.href =
          //   res.data.ip + "?code=" + res.data.code;
          window.open(res.data.ip + "?code=" + res.data.code, "_blank");
        }
      });
      // window.location.href = this.$api;
    },
    delCookie() {
      // this.$cookies.keys()
      Cookies.set("person", "", {
        expires: 0,
        path: "",
        domain: ".hj-blks.com",
      });
      Cookies.set("uuid", "", { expires: 0, path: "", domain: ".hj-blks.com" });
      Cookies.set("Admin-Token", "", {
        expires: 0,
        path: "",
        domain: ".hj-blks.com",
      });
    },
    clear() {
      this.$dialog
        .alert({
          title: "提示", //加上标题
          message: "是否退出账号", //改变弹出框的内容
          showCancelButton: true, //展示取水按钮
        })
        .then(() => {
          //点击确认按钮后的调用
          Cookies.remove("person");
          Cookies.remove("uuid");
          Cookies.remove("Admin-Token");
          // this.delCookie(); //调用

          location.reload();
        })
        .catch(() => {
          //点击取消按钮后的调用
          console.log("点击了取消按钮噢");
        });
    },
  },
};
</script>

<style scoped>
.examineeData {
  padding: 40px;
  min-width: 300px;
  box-sizing: border-box;
}

.title {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  opacity: 1;
}

.slogan {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #707070;
  opacity: 1;
  margin: 16px 0px 24px 0px;
}
.button-list {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}
.bottom-item {
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  color: #e01811;
  text-align: center;
  line-height: 40px;
  border: 1px solid #e01811;
  opacity: 1;
  border-radius: 2px;
  margin-bottom: 32px;
  cursor: pointer;
}

.unsubscribe {
  color: #ffffff;
  background: #e01811;
}
</style>