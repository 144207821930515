import { render, staticRenderFns } from "./testremindLiast.vue?vue&type=template&id=23049db0&scoped=true&"
import script from "./testremindLiast.vue?vue&type=script&lang=js&"
export * from "./testremindLiast.vue?vue&type=script&lang=js&"
import style0 from "./testremindLiast.vue?vue&type=style&index=0&id=23049db0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23049db0",
  null
  
)

export default component.exports