var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"recruitment-view"},[_c('div',{staticClass:"left-recruitment-box"},[_c('div',{staticClass:"recruitment-head"},[_c('div',{staticClass:"title-box"},[_c('span',{staticClass:"house-icon",style:({ background: `url(${_vm.tabs.url}) no-repeat 100% / 100%` })}),_c('div',{staticClass:"recruitment-title"},[_vm._v(_vm._s(_vm.tabs.leftTitle))])]),_c('div',{staticClass:"more-icon-box",on:{"click":function($event){return _vm.chooseTypePage()}}},[_vm._v(" 更多 "),_c('span',{staticClass:"more-icon"})])]),_c('div',{staticClass:"tabs"},_vm._l((_vm.tabs.recruitmentTabs),function(item,index){return _c('div',{key:index,staticClass:"tab-item",class:{ active: _vm.activeIndex === index },on:{"click":function($event){return _vm.switchList(index, item)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0),_c('div',{staticClass:"notice-list-box"},_vm._l((_vm.announcementListData),function(item,i){return _c('div',{key:i,staticClass:"ontoce-item"},[_c('div',{staticClass:"notice-title"},[_c('i',{class:{ active: _vm.noticeIndex === i },on:{"mouseover":function($event){return _vm.noticeMouseover(i)},"mouseleave":_vm.noticeMouseleave,"click":function($event){return _vm.JumpDetailsPage(item.ywId)}}},[_vm._v(" "+_vm._s(item.ywTitle))])]),_c('div',{staticClass:"notice-date"},[_vm._v(_vm._s(item.date))])])}),0)]),(_vm.rightShows)?_c('div',{staticClass:"right-guide"},[_vm._m(0),_c('div',{staticClass:"imgBigBox"},_vm._l((_vm.imgList),function(item,index){return _c('img',{key:index,staticClass:"imgBox",attrs:{"src":item.imgs,"alt":""},on:{"click":function($event){return _vm.jumpImg(item)}}})}),0)]):_c('div',{staticClass:"right-guide"},[_c('div',{staticClass:"duide-top"},[_c('span',{staticClass:"guide-title"},[_vm._v(_vm._s(_vm.tabs.rigthTitle))])]),(!_vm.isHot)?_c('div',{staticClass:"duide-list"},_vm._l((_vm.guideList),function(item,index){return _c('div',{key:index,staticClass:"duide-item",class:{
          hot: _vm.isHot,
          'guide-active': _vm.shiftupactive === index,
        },on:{"mouseover":function($event){return _vm.guideMouseover(index)},"mouseleave":_vm.guideMouseleave,"click":function($event){return _vm.toGuidePage(item.bzId)}}},[_vm._v(" "+_vm._s(item.bzTitle)+" ")])}),0):_c('div',{staticClass:"duide-list"},_vm._l((_vm.guideList),function(item,index){return _c('div',{key:index,staticClass:"duide-item",class:{
          hot: _vm.isHot,
          'hot-active': _vm.shiftupactive === index,
        },on:{"mouseover":function($event){return _vm.guideMouseover(index)},"mouseleave":_vm.guideMouseleave,"click":function($event){return _vm.toGuidePage(item.ywId)}}},[_vm._v(" "+_vm._s(item.ywTitle)+" ")])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"duide-top"},[_c('span',{staticClass:"guide-title"},[_vm._v("人才测评管理系统")])])
}]

export { render, staticRenderFns }