<template>
  <div class="recruitment-view">
    <!-- 单位招聘组件 -->

    <!-- 左边招聘框 -->
    <div class="left-recruitment-box">
      <!-- 头部 标题、图标 右边更多 -->
      <div class="recruitment-head">
        <!-- 标题 +  图标 -->
        <div class="title-box">
          <span
            class="house-icon"
            :style="{ background: `url(${tabs.url}) no-repeat 100% / 100%` }"
          ></span>
          <div class="recruitment-title">{{ tabs.leftTitle }}</div>
        </div>

        <!-- 更多 -->
        <div class="more-icon-box" @click="chooseTypePage()">
          更多
          <span class="more-icon"></span>
        </div>
      </div>

      <!-- tab list -->
      <div class="tabs">
        <div
          class="tab-item"
          :class="{ active: activeIndex === index }"
          v-for="(item, index) in tabs.recruitmentTabs"
          :key="index"
          @click="switchList(index, item)"
        >
          {{ item.title }}
        </div>
      </div>

      <!-- 通知列表框 -->
      <div class="notice-list-box">
        <div
          class="ontoce-item"
          v-for="(item, i) in announcementListData"
          :key="i"
        >
          <!-- 通知 / 公告 标题 -->
          <div class="notice-title">
            <i
              :class="{ active: noticeIndex === i }"
              @mouseover="noticeMouseover(i)"
              @mouseleave="noticeMouseleave"
              @click="JumpDetailsPage(item.ywId)"
            >
              {{ item.ywTitle }}</i
            >
          </div>

          <!-- 通知 / 公告 日期时间 -->
          <div class="notice-date">{{ item.date }}</div>
        </div>
      </div>
    </div>
    <div class="right-guide" v-if="rightShows">
      <!-- 头部标题栏 -->
      <div class="duide-top">
        <span class="guide-title">人才测评管理系统</span>
      </div>
      <!-- 广告图 -->
      <div class="imgBigBox">
        <img
          v-for="(item, index) in imgList"
          :key="index"
          class="imgBox"
          :src="item.imgs"
          alt=""
          @click="jumpImg(item)"
        />
      </div>
    </div>
    <!-- 右边指引框 -->
    <div class="right-guide" v-else>
      <!-- 头部标题栏 -->
      <div class="duide-top">
        <span class="guide-title">{{ tabs.rigthTitle }}</span>
      </div>
      <!-- 指引选项列表 -->
      <div class="duide-list" v-if="!isHot">
        <div
          class="duide-item"
          v-for="(item, index) in guideList"
          :key="index"
          :class="{
            hot: isHot,
            'guide-active': shiftupactive === index,
          }"
          @mouseover="guideMouseover(index)"
          @mouseleave="guideMouseleave"
          @click="toGuidePage(item.bzId)"
        >
          {{ item.bzTitle }}
        </div>
      </div>

      <div class="duide-list" v-else>
        <div
          class="duide-item"
          v-for="(item, index) in guideList"
          :key="index"
          :class="{
            hot: isHot,
            'hot-active': shiftupactive === index,
          }"
          @mouseover="guideMouseover(index)"
          @mouseleave="guideMouseleave"
          @click="toGuidePage(item.ywId)"
        >
          {{ item.ywTitle }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: [Object],
      delete: [],
    },
    guideList: {
      type: [Array],
      delete: [],
    },
    // 公告列表数据
    announcementListData: {
      type: [Array],
      delete: [],
    },
    // 判断是否是事业单位组件，事业单位指引列表和热门指引列表样式不同
    isHot: {
      type: [Boolean],
      delete: false,
    },
    imgList: {
      type: [Array],
      delete: [],
    },
    // 是否显示右边的广告图
    rightShows: {
      type: [Boolean],
      delete: false,
    },
  },
  data() {
    return {
      activeIndex: 0,
      title: "招聘公告",
      ywType: "招聘公告",
      shiftupactive: NaN, //  右边指引列表项，鼠标移上高亮
      noticeIndex: NaN, // 通知列表标题高亮下标
    };
  },
  methods: {
    chooseTypePage() {
      var i = 0;
      // console.log(this.isHot, "this.isHot");
      if (this.isHot == false) {
        if (this.title == "招聘公告") {
          i = 1;
        }
        if (this.title == "网上报名") {
          i = 4;
        }
        if (this.title == "准考证打印时间") {
          i = 6;
        }
        if (this.title == "成绩查询时间") {
          i = 7;
        }
        if (this.title == "延长报名时间") {
          i = 11;
        }
      } else {
        if (this.title == "招聘公告") {
          i = 2;
        }
        if (this.title == "网上报名") {
          i = 5;
        }
        if (this.title == "准考证打印时间") {
          i = 6;
        }
        if (this.title == "成绩查询时间") {
          i = 7;
        }
        if (this.title == "延长报名时间") {
          i = 11;
        }
      }
      // var href =
      //   "/affichebm?ywType=" +
      //   this.ywType +
      //   "&index=" +
      //   i +
      //   "&title=" +
      //   this.title;
      // window.open(href, "_blank");
      this.$router.push({
        path: "/affichebm",
        query: {
          ywType: this.ywType,
          index: i,
          title: this.title,
        },
      });
    },
    // 广告图详情
    jumpImg(item) {
      this.$router.push({
        path: "/adventImg",
        query: {
          id: item.id,
        },
      });
    },
    switchList(i, v) {
      this.activeIndex = i;
      this.title = v.title;
      this.ywType = v.title;
      var title = v.title;
      var ywType = v.title;
      if (
        v.title.indexOf("招聘公告") != -1 ||
        v.title.indexOf("网上报名") != -1
      ) {
        ywType = "招聘公告";
        title = "招聘公告";
      } else if (v.title.indexOf("成绩查询") != -1) {
        ywType = "成绩查询公告";
        title = "成绩查询公告";
      } else if (v.title.indexOf("聘用公示") != -1) {
        ywType = "录用公示公告";
        title = "录用公示公告";
      }
      this.$emit("getDw", ywType);
      this.$emit("getNoDw", title);
    },

    // 移上
    guideMouseover(i) {
      this.shiftupactive = i;
    },

    // 移出
    guideMouseleave() {
      this.shiftupactive = NaN;
    },

    // 通知标题
    // 移上
    noticeMouseover(i) {
      this.noticeIndex = i;
    },

    // 移出
    noticeMouseleave() {
      this.noticeIndex = NaN;
    },
    // 跳转招聘详情页
    JumpDetailsPage(id) {
      this.$router.push({ name: "RecruitDetaiks", params: { id: id } });
    },
    // 跳转指引详情页
    toGuidePage(id) {
      if (this.isHot) {
        this.$router.push({ name: "RecruitDetaiks", params: { id: id } });
        return;
      }
      this.$router.push({ name: "guidePage", params: { id: id } });
    },
  },
};
</script>


<style scoped>
.recruitment-view {
  height: 558px;
  background: #ffffff;
  padding: 40px 54px;
  display: flex;
  box-sizing: border-box;
  margin-top: 50px;
  overflow: hidden;
}

.left-recruitment-box {
  width: 69%;
  margin-right: 60px;
}

.recruitment-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-box {
  display: flex;
  align-items: center;
}

.recruitment-title {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 0px;
  color: #e01811;
  opacity: 1;
}
.house-icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: url("../../../assets/images/house-icon.png") no-repeat 100%/100%;
  margin-right: 4px;
}

.more-icon {
  display: inline-block;
  width: 28px;
  height: 16px;
  background: url("../../../assets/images/gray-rigtharrow-icon.png") no-repeat
    100% / 100%;
}

.more-icon-box {
  color: #999999;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tabs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0px;
}

.tab-item {
  /* width: 168px; */
  width: 20%;
  height: 40px;
  color: #555555;
  background: #f8f8f8;
  opacity: 1;
  border-radius: 0px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.tab-item.active {
  color: #ffffff;
  background: #e01811;
}

.title.active {
  color: #e01811;
  text-decoration: underline;
}

.notice-list-box {
  height: 320px;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.notice-list-box::-webkit-scrollbar,
.imgBigBox::-webkit-scrollbar {
  display: none;
}

.ontoce-item {
  color: #333333;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  line-height: 48px;
}

i {
  font-style: normal;
  cursor: pointer;
}
.notice-title > i.active {
  color: #e01811;
  text-decoration: underline;
}

.notice-date {
  color: #999999;
}
.right-guide {
  width: 40%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.imgBigBox {
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  flex-wrap: wrap;
  height: 422px;
  overflow: hidden;
  overflow-y: auto;
}
.imgBox {
  width: 48%;
  margin-bottom: 16px;
  display: block;
}

@media screen and (max-width: 1200px) {
  /* 当屏幕小于或等于 1200px 时应用样式 */
  .imgBox {
    width: 100%;
  }
}
.guide-title {
  color: #e01811;
  font-size: 24px;
  font-weight: 500;
}

.duide-top {
  margin-bottom: 24px;
}

.duide-list {
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}

.duide-list::-webkit-scrollbar {
  display: none;
}

.duide-item {
  width: 100%;
  height: 50px;
  color: #c74548;
  text-align: center;
  background: #fef3ed;
  line-height: 50px;
  margin-bottom: 24px;
  white-space: nowrap;
  cursor: pointer;
}

.duide-item:last-child {
  margin-bottom: 0px;
}

.hot {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  background: #f7f7f7;
  opacity: 1;
  border-radius: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 20px;
  box-sizing: border-box;
}
.guide-active {
  color: #ffffff;
  background: #e01811;
}

.hot-active {
  color: #e01811;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  background: #fef3ed;
  opacity: 1;
}
</style>