<template>
  <div class="remind-view" v-if="convocationNoticeList">
    <div class="recruitment-title-box">
      <div class="title-item">{{ title1 }}</div>
      <div class="title-date">{{ title2 }}</div>
    </div>

    <div class="affiche-list">
      <div
        class="affiche-item"
        v-for="(item, index) in convocationNoticeList"
        :key="index"
      >
        <div class="title">
          <i
            @mouseover="mouseover(index)"
            @mouseleave="mouseleave"
            :class="{ 'title-active': isActive === index }"
            @click="JumpDetailsPage(item.ywId, item.rlink)"
            >{{ item.ywTitle }}</i
          >
        </div>
        <div class="time-box">
          <!-- 开始时间 -->
          <span class="time start-time">{{
            item.ywStartTime.replace(/-/g, "/").substr(0, 16)
          }}</span
          >至
          <!-- 截止时间 -->
          <span class="time end-time">{{
            item.ywEndTime.replace(/-/g, "/").substr(0, 16)
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    convocationNoticeList: {
      type: [Array],
      delete: [],
    },

    title1: {
      type: String,
      required: true,
      default: "招聘项目",
    },
    title2: {
      type: String,
      required: true,
      default: "报名时间",
    },
  },
  data() {
    return {
      isActive: null,
    };
  },
  computed: {},
  methods: {
    // 鼠标移上 标题添加高亮
    mouseover(i) {
      this.isActive = i;
    },

    // 鼠标移出事件
    mouseleave() {
      this.isActive = null;
    },

    // 点击跳转详情页
    JumpDetailsPage(id, url) {
      if (url) {
        window.open(url, "_blank");
      } else {
        this.$router.push({ name: "RecruitDetaiks", params: { id: id } });
      }
    },
  },
};
</script>

<style scoped>
.remind-view {
  width: 100%;
  color: #333333;
  background: #ffffff;
}

.recruitment-title-box {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  background: #f5f5f5;
}

.title-item {
  width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-date {
  width: 38%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.affiche-list {
  padding: 20px 24px;
  height: 299px;
  overflow-y: auto;
  box-sizing: border-box;
}

.affiche-list::-webkit-scrollbar {
  display: none;
}
.affiche-item {
  font-weight: 500;
  display: flex;
  line-height: 44px;
  justify-content: space-between;
}

.title {
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

i {
  cursor: pointer;
  font-style: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.title-active {
  color: #e01811;
  text-decoration: underline;
}

.time-box {
  color: #e01811;
  width: 38%;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.time {
  color: #e01811;
  font-size: 16px;
}
</style>
