<template>
  <div class="login-component">
    <!-- 登录组件 -->
    <div class="login-tabs">
      <div
        class="btn"
        :class="{ active: loginType === i }"
        v-for="(item, i) in btnList"
        :key="i"
        @click="switchLogin(i)"
      >
        {{ item.title }}
      </div>
    </div>

    <!-- 登录表单 -->
    <div class="form-box">
      <!-- 账号 -->
      <div class="input-item">
        <span class="account-icon"></span>
        <input
          type="text"
          v-model="loginForm.ksUser"
          placeholder="请输入您的账号"
        />
      </div>

      <!-- 密码 -->
      <div class="input-item">
        <span class="password-icon"></span>
        <input
          type="password"
          v-model="loginForm.ksPassword"
          placeholder="请输入您的密码"
        />
      </div>

      <!-- 验证码 -->
      <!-- <div class="input-item">
        <span class="validation-icon"></span>
        <div style="width: 100%; display: flex; justify-content: space-between">
          <div style="width: 70%">
            <input
              type="text"
              v-model="loginForm.code"
              placeholder="请输入验证码"
            />
          </div>
          <div style="width: 25%">
            <img
              :src="codeUrl"
              v-if="codeUrl"
              @click="getCode"
              class="login-code-img"
            />
          </div>
        </div>
      </div> -->
      <div
        style="
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-bottom: 30px;
        "
      >
        <div style="width: 65%">
          <el-input
            v-model="loginForm.code"
            auto-complete="off"
            placeholder="验证码"
            @keyup.enter.native="handleLogin"
          >
          </el-input>
        </div>
        <div class="login-code" style="width: 30%">
          <img :src="codeUrl" @click="getCode" class="login-code-img" />
        </div>
      </div>

      <div class="login-btn" @click="goLogin">登 录</div>
      <div class="register">
        <el-link type="danger" @click="register">注 册</el-link>
        <el-link type="danger" @click="forgotpassword">忘记密码</el-link>
      </div>
    </div>
  </div>
</template>

<script>
import common from "../../../api/common.js";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      btnList: [
        {
          title: "考生入口",
        },
      ],
      loginForm: {
        ksUser: "",
        ksPassword: "",
        rememberMe: false,
        code: "",
        uuid: "",
      },
      loginType: 0, // 登录类型 0 考生， 1 单位
      codeUrl: "",
    };
  },
  created() {
    this.getCode();
  },
  methods: {
    // 注册
    register() {
      window.open("https://ksbm.hj-blks.com/letter", "_blank");
    },
    // 忘记密码
    forgotpassword() {
      window.open("https://ksbm.hj-blks.com/forgot", "_blank");
    },
    getCode() {
      common.digitRandomCode().then((res) => {
        console.log(res, "res");
        this.codeUrl = "data:image/gif;base64," + res.img;
        this.loginForm.uuid = res.uuid;
      });
    },
    goLogin() {
      //var href =this.$api+"#/login";
      //window.open(href, "_blank");
      var ksUser = this.loginForm.ksUser;
      var ksPassword = this.loginForm.ksPassword;
      var code = this.loginForm.code;
      var uuid = this.loginForm.uuid;
      if (!ksUser) {
        alert("账号不为空");
        return;
      }
      if (!ksPassword) {
        alert("密码不为空");
        return;
      }
      if (!code) {
        alert("验证码不为空");
        return;
      }
      common
        .login(ksUser, ksPassword, code, uuid)
        .then((res) => {
          if (res.code == 200) {
            Cookies.set("person", JSON.stringify(res.data));
            Cookies.set("uuid", res.data.uuid);
            Cookies.set("Admin-Token", res.data.uuid);
            // Cookies.set("person", JSON.stringify(res.data), {
            //   expires: 1,
            //   path: "",
            //   domain: "localhost",
            // });
            // Cookies.set("uuid", res.data.uuid, {
            //   expires: 1,
            //   path: "",
            //   domain: "localhost",
            // });
            // Cookies.set("Admin-Token", res.data.uuid, {
            //   expires: 1,
            //   path: "",
            //   domain: "localhost",
            // });
            alert("登录成功");
            location.reload();
          } else {
            this.getCode();
            alert(res.data.msg);
          }
        })
        .catch((res) => {
          console.log(res);
          this.getCode();
        });
    },
    switchLogin(i) {
      this.loginType = i;
    },
  },
};
</script>

<style scoped>
.login-component {
  width: 100%;
  height: 100%;
  min-width: 300px;
}
.form-box {
  padding: 0px 40px;
}

.login-tabs {
  background: #ffffff;
  padding-left: 40px;
  padding-top: 40px;
  display: flex;
  align-items: center;
  padding-bottom: 40px;
}
.login-code-img {
  width: 100px;
}
.btn {
  width: 88px;
  height: 28px;
  color: #333333;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.btn:nth-child(1) {
  margin-right: 40px;
}

.btn.active {
  color: #e01811;
  position: relative;
}

.btn.active::after {
  content: "";
  width: 24px;
  height: 4px;
  border-radius: 2px;
  background: #e01811;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.input-item {
  width: 100%;
  height: 40px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.input-item:nth-child(3) {
  width: 100%;
}

.input-item input {
  width: 100%;
  height: 100%;
  /* color: #999999; */
  font-size: 14px;
  background: none;
  outline: none;
  border: none;
}

.account-icon,
.password-icon,
.validation-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url(../../../assets/images/account-icon.png) no-repeat 100%/100%;
  margin: 12px 16px;
}

.password-icon {
  background: url(../../../assets/images/password-icon.png) no-repeat 100%/100%;
}
.validation-icon {
  background: url(../../../assets/images/validation-icon.png) no-repeat 100%/100%;
}

.login-btn {
  width: 100%;
  height: 40px;
  color: #ffffff;
  background: #e01811;
  border-radius: 2px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}
.register {
  display: flex;
  justify-content: space-evenly;
  padding-top: 16px;
  text-align: center;
}
.el-link.el-link--danger {
  color: #e01811;
  font-size: 16px;
}
</style>