<template>
  <div class="home-view">
    <div class="content">
      <!-- 考试提醒 -->

      <div class="remind-box">
        <!-- 左考试提醒框 -->
        <div class="left-remind">
          <div class="remind-top">
            <div class="title-box">
              <span class="clock-icon"></span>
              <div class="title">考试提醒</div>
            </div>

            <!-- table  列表-->
            <div class="tab-box">
              <div class="list">
                <div
                  class="item"
                  :class="{ active: activeIndex === index }"
                  v-for="(item, index) in tabsb"
                  :key="index"
                  @click="chooseType(item, index)"
                >
                  {{ item.title }}
                </div>
              </div>
            </div>

            <!-- 更多 -->
            <div class="more-box">
              <span class="title" @click="chooseTypePage()">更多</span>
              <span class="more-icon"></span>
            </div>
          </div>

          <div class="remind-content">
            <testremindLiast
              :convocationNoticeList="convocationNoticeList"
              :title1="title1"
              :title2="title2"
            />
          </div>
        </div>

        <!-- 右边登录框 -->
        <div class="right-bigbox">
          <div class="rigth-login">
            <login v-if="!token" />
            <examineeData v-if="token" />
          </div>
          <div class="codeBox">
            <div class="font1">考试信息</div>
            <el-image
              style="width: 160px; height: 160px; margin-top: 40px"
              :src="imgUrl"
              fit="cover"
              :preview-src-list="[imgUrl]"
            >
            </el-image>
            <div
              style="
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                text-align: center;
                margin-top: 16px;
                font-size: 16px;
              "
            >
              <div>关注公众号</div>
              <div>了解更多考试信息</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 广告框 -->
      <div class="advertising-box" style="display: none"></div>
      <!-- 市直单位招聘公告栏 -->
      <!-- <municipalUnit
        :tabs="municipalUnit"
        :guideList="guideTypeList"
        :announcementListData="municipalList"
        @getDw="getDw"
      /> -->
      <!-- 单位招聘 -->
      <unitrecruitment
        :tabs="unitRecruitmentList"
        :guideList="guideTypeList"
        :announcementListData="unitAnnouncementList"
        @getDw="getDw"
      />
      <!-- 广告图 -->
      <!-- <div class="imgBigBox" v-if="imgList.length > 0">
        <img
          v-for="(item, index) in imgList"
          :key="index"
          class="imgBox"
          :src="item.imgs"
          alt=""
          @click="jumpImg(item)"
        />
      </div> -->
      <unitrecruitment
        :tabs="otherRecruitment"
        :guideList="hotRecruitment"
        :announcementListData="otherAnnouncementList"
        @getNoDw="getNoDw"
        :imgList="imgList"
        :rightShows="true"
        :isHot="true"
      />
    </div>
  </div>
</template>

<script scoped>
// import naviGationBar from "@/components/naviGationBar.vue"
import testremindLiast from "./components/testremindLiast.vue";
// 单位招聘组件
// import municipalUnit from "@/components/municipalUnit.vue";
import unitrecruitment from "./components/unitrecruitment.vue";

// 登录组件
import login from "./components/login.vue";

// 导入已登录组件
import examineeData from "./components/examineeData.vue";
import Cookies from "js-cookie";
// 导入封装的请求方法文件
import common from "../../api/common";
export default {
  components: {
    testremindLiast,
    unitrecruitment,
    login,
    examineeData,
  },
  data() {
    return {
      token: Cookies.get("person"),
      // 用来获取对应公告的参数
      ywType: { ywType: "招聘公告" },
      tabsb: [
        {
          title: "考试报名时间",
          ywType: "招聘公告",
          title1: "项目名称",
          title2: "报名时间",
        },
        {
          title: "准考证打印时间",
          ywType: "准考证公告",
          title1: "项目名称",
          title2: "打印时间",
        },
        {
          title: "成绩查询时间",
          ywType: "成绩查询公告",
          title1: "项目名称",
          title2: "查询时间",
        },
        {
          title: "延长报名时间",
          ywType: "延迟报名公告",
          title1: "项目名称",
          title2: "查询时间",
        },
      ],
      title: "",
      titles: "网上报名",
      title1: "项目",
      title2: "报名时间", 
      imgList: [], 
      // 市直单位招聘
      municipalUnit: {
        leftTitle: "市直单位招聘",
        url: require("../../assets/images/house-icon1.png"),
        rigthTitle: "操作指引",
        recruitmentTabs: [
          {
            title: "招聘公告",
            ywType: "招聘公告",
          },
          {
            title: "网上报名",
            ywType: "招聘公告",
          },
          {
            title: "成绩查询",
            ywType: "成绩查询公告",
          },
          {
            title: "相关通知",
            ywType: "相关通知",
          },
          {
            title: "聘用公示",
            ywType: "聘用公示",
          },
        ],
      },
      // 事业单位招聘列表
      unitRecruitmentList: {
        leftTitle: "事业单位招聘",
        url: require("../../assets/images/house-icon.png"),
        rigthTitle: "操作指引",
        recruitmentTabs: [
          {
            title: "招聘公告",
            ywType: "招聘公告",
          },
          {
            title: "网上报名",
            ywType: "招聘公告",
          },
          {
            title: "成绩查询",
            ywType: "成绩查询公告",
          },
          {
            title: "相关通知",
            ywType: "相关通知",
          },
          {
            title: "聘用公示",
            ywType: "聘用公示",
          },
        ],
      },
      otherAnnouncementList: [],
      // 单位公告列表
      unitAnnouncementList: [],
      // municipalList: [],

      otherRecruitment: {
        leftTitle: "其它招聘",
        rigthTitle: "热点招聘",
        url: require("../../assets/images/other-recruitment-icon.png"),
        recruitmentTabs: [
          {
            title: "招聘公告",
            ywType: "招聘公告",
          },
          {
            title: "网上报名",
            ywType: "招聘公告",
          },
          {
            title: "成绩查询",
            ywType: "成绩查询公告",
          },
          {
            title: "相关通知",
            ywType: "相关通知",
          },
          {
            title: "聘用公示",
            ywType: "聘用公示",
          },
        ],
      },

      activeIndex: 0,
      // 考试提醒按钮标
      remindIndex: 0,

      // 指引列表
      guideTypeList: [],

      // 热点招聘列表
      hotRecruitment: [],

      // 通知
      convocationNoticeList: [],
      dwType: "事业单位",
      dwType1: "其他单位",
      imgUrl: "",
    };
  },
  mounted() {
    // 调用获取公告类型数据
    this.getList();
    // 调用获取指引类型列表
    this.getGuideTypeList();
    this.getImg();
  },
  methods: {
    getImg() {
      // 二维码
      var param = {
        dictType: "mang",
      };
      common.listData(param).then((res) => {
        if (res.code == 200) {
          if (res.rows) {
            this.imgUrl = res.rows[0].dictLabel;
          }
        }
      });
      // 广告图
      common.productList().then((res) => {
        if (res.code == 200) {
          this.imgList = res.data;
        }
      });
    },
    // 广告图详情
    jumpImg(item) {
      this.$router.push({
        path: "/adventImg",
        query: {
          id: item.id,
        },
      });
    },
    //  考试提醒那一块;点击切换类型
    chooseType(data, i) {
      // console.log(data, "123123");
      this.activeIndex = i;
      this.ywType = {
        ywType: data.ywType,
      }; // 修改参数用来获取对应的数据的

      this.title1 = data.title1;
      this.title2 = data.title2;
      // console.log(this.title1);
      this.title = data.title;
      this.titles = data.title;
      // 调用获取对应的数据
      // this.getList();
      this.convocationNoticeList = [];
      var items = "招聘公告";
      if (this.title == "考试报名时间") {
        this.titles = "网上报名";
        items = "招聘公告";
      } else if (this.title == "准考证打印时间") {
        this.titles = "准考证打印";
        items = "准考证公告";
      } else if (this.title == "成绩查询时间") {
        this.titles = "成绩查询";
        items = "成绩查询公告";
      } else if (this.title == "延长报名时间") {
        this.titles = "考试报名延长时间";
        items = "延迟考试公告";
      }
      common.getTweePagingtList("", items, "", "").then((res) => {
        this.convocationNoticeList = res.rows;
      });
    },
    chooseTypePage() {
      // console.log(this.title, "this.titlethis.title");
      var i = 0;
      if (this.title == "考试报名时间") {
        i = 0;
      }
      if (this.title == "准考证打印时间") {
        i = 6;
      }
      if (this.title == "成绩查询时间") {
        i = 7;
      }
      if (this.title == "延长报名时间") {
        i = 11;
      }
      // var href =
      //   "/affichebm?ywType=" +
      //   this.ywType.ywType +
      //   "&index=" +
      //   i +
      //   "&title=" +
      //   this.title;
      // window.open(href, "_blank");

      this.$router.push({
        path: "/affichebm",
        query: {
          ywType: this.ywType,
          index: i,
          title: this.titles,
        },
      });
    },
    // 获取公告对应列表数据
    getList() {
      // 考试提醒那一块;
      var items = "招聘公告";
      common.getTweePagingtList("", items, "", "").then((res) => {
        this.convocationNoticeList = res.rows;
      });
      // common.getTweetsList(this.ywType).then((res) => {
      //   this.convocationNoticeList = res.rows;
      // });
      this.hotRecruitment = [];
      var data = {};
      common.getHotRecruitment(data).then((res) => {
        // console.log(res.rows);
        this.hotRecruitment = res.rows;
      });
      //单位招聘
      this.unitAnnouncementList = [];
      data = { ywType: "招聘公告" };
      this.ywType = data.ywType;
      common
        .getTweePagingtList("", this.ywType, "", this.dwType)
        .then((res) => {
          this.unitAnnouncementList = res.rows;
        });
      // common.getUnitRecruitment(data).then((res) => {
      //   // console.log(res.rows);
      //   this.unitAnnouncementList = res.rows;
      // });
      //其他招聘单位
      this.otherAnnouncementList = [];
      data = { ywType: "招聘公告" };
      this.ywType = data.ywType;
      common
        .getTweePagingtList("", this.ywType, "", this.dwType1)
        .then((res) => {
          this.otherAnnouncementList = res.rows;
        });
      // common.getOtherRecruitment(data).then((res) => {
      //   // console.log(res.rows);
      //   this.otherAnnouncementList = res.rows;
      // });
      // // 获取市直单位招聘
      // var param = {};
      // common.recruitmentMunicipal(param).then((res) => {
      //   this.municipalList = res.rows;
      // });
    },
    getNoDw(ywType) {
      this.otherAnnouncementList = [];
      // var data = { ywType: ywType };
      // common.getOtherRecruitment(data).then((res) => {
      //   // console.log(res.rows);
      //   this.otherAnnouncementList = res.rows;
      // });
      if (ywType.indexOf("成绩查询") != -1) {
        this.dwType1 = "";
      } else {
        this.dwType1 = "其他单位";
      }
      common.getTweePagingtList("", ywType, "", this.dwType1).then((res) => {
        this.otherAnnouncementList = res.rows;
      });
    },
    getDw(ywType) {
      this.unitAnnouncementList = [];
      // var data = { ywType: ywType };
      // common.getUnitRecruitment(data).then((res) => {
      //   // console.log(res.rows);
      //   this.unitAnnouncementList = res.rows;
      // });
      if (ywType.indexOf("成绩查询") != -1) {
        this.dwType = "";
      } else {
        this.dwType = "事业单位";
      }
      common.getTweePagingtList("", ywType, "", this.dwType).then((res) => {
        this.unitAnnouncementList = res.rows;
      });
    },
    // 获取指引列表
    getGuideTypeList() {
      common.getGuideList().then((res) => {
        console.log(res.rows);
        // this.guideList =
        this.guideTypeList = res.rows;
      });
    },
  },
};
</script>

<style scoped>
.home-view {
  width: 100%;
  background: #f5f5f5;
}

.content {
  padding-bottom: 40px;
}

@media screen and (max-width: 1300px) {
  /* 当屏幕小于或等于 1300px 时应用样式 */
  .remind-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .left-remind {
    width: 100% !important;
    margin-right: 0 !important;
  }
  .right-bigbox {
    margin-top: 24px;
    width: 100% !important;
    display: flex;
  }
}
.remind-box {
  width: 100%;
  /* height: 444px; */
  display: flex;
}

/* 右边提醒框 */
.left-remind {
  margin-right: 20px;
  width: 60%;
  background: #ffffff;
}

.title-box {
  display: flex;
  align-items: center;
}

.remind-top {
  display: flex;
  height: 88px;
  width: 100%;
  background: #e01811;
}

.clock-icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: url("../../assets/images/clock-icon.png") no-repeat 100% / 100%;
  margin: 0px 8px 0 16px;
}

.title-box > .title {
  color: #ffffff;
  font-size: 25px;
}

.tab-box {
  width: 100%;
  flex: 1;
  position: relative;
  display: flex;
}

.list {
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  left: 0;
}

.item {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  padding: 12px 16px;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
}

.item.active {
  background: #ffffff;
  color: #e01811;
}

.more-box {
  display: flex;
  align-items: center;
  padding-right: 24px;
  cursor: pointer;
}

.recruitment-title-box {
  color: #333333;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  padding: 14px 0px;
  background: #f5f5f5;
}

.title-item {
  text-indent: 24px;
  width: 70%;
}

.title-date {
  flex: 1;
}

.more-box > .title {
  color: #ffffff;
  font-size: 16px;
}

.more-icon {
  display: inline-block;
  width: 28px;
  height: 16px;
  background: url("../../assets/images/rightArrow.png") no-repeat 100%/100%;
}
.right-bigbox {
  width: 45%;
  display: flex;
}
.codeBox {
  width: 20%;
  min-width: 240px;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 40px;
}
.font1 {
  font-size: 20px;
  color: #333333;
  font-weight: bold;
}
/* 登录 */
.rigth-login {
  width: 80%;
  flex: 1;
  height: 100%;
  background: #ffffff;
  /* padding-bottom: 40px; */
  margin-right: 20px;
  min-width: 300px;
}
.imgBigBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 40px;
}
.imgBox {
  width: 48%;
  margin-top: 16px;
  display: block;
}

.advertising-box {
  height: 370px;
  background: #ffe6e6;
  opacity: 1;
  border-radius: 0px;
  margin-top: 40px;
}
</style>